
.tiny {
  width: 100%;

  h1 {
    font-family: $montserrat;
    font-weight: 700;
    font-size: 3rem;
    color: $blue-dark;
    margin: 1.5rem 0;
  }

  h2 {
    font-family: $marcellus;
    font-size: 2.4rem;
    color: $green;
    margin: 1.2rem 0;
  }

  h3 {
    font-family: $montserrat;
    font-weight: 700;
    font-size: 1.8rem;
    color: $grey4;
    margin: 0.9rem 0;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  p {
    font-family: $montserrat;
    font-size: 1.5rem;
    line-height: 1.6;
    color: $grey4;
    margin: 1rem 0;
  }

  .legend {
    font-family: $marcellus;
    font-size: 1.2rem;
    color: $grey4;
    margin: 0.6rem 0;
  }


  ul {
    li {
      padding: 0;
      padding-left: 30px;
      position: relative;
      margin: 5px 15px;
      line-height: 1.6;

      font-family: $montserrat;
      font-size: 1.5rem;
      color: $grey4;

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(5px);
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background-color: $green;
      }
    }
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  .quality {
    width: 100%;
    //align-items: center;
    margin: 15px 0;
    // flex-direction: column;

    &.green {
      border: 5px solid $green;

      .logo {
        background-color: $green;
      }
    }

    &.blue {
      border: 5px solid $blue;

      .logo {
        background-color: $blue;
      }
    }

    .content {
      background-color: white;
      margin: 0;
      padding: 5px;
      p {
        font-size: 1.2rem;
      }
    }

    .logo img {
      margin: 10px auto;
      width: 80%;
    }

    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      margin: 15px 0;
      flex-direction: column;

      .item {
        width: 100%;
      }

      .logo {
        margin-bottom: 5px;
        padding: 0 0 5px 0;
      }
    }

    @media (min-width: 768px) {
      display: table;

      .item {
        display: table-cell;
        vertical-align: middle;
      }

      .content {
        padding: 0px 10px;
        min-height: 150px;
        width: 100% / 3 * 2;
      }
    }
  }

}
